// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-rv-list-7p4dJZtbmwr{overflow:visible !important}.list-rv-list-7p4dJZtbmwr:focus{outline:none}", "",{"version":3,"sources":["webpack://client/shared/list/list.scss"],"names":[],"mappings":"AAAA,0BACE,2BAAA,CACA,gCACE,YAAA","sourcesContent":[".rv-list {\n  overflow: visible !important;\n  &:focus {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rv-list": "list-rv-list-7p4dJZtbmwr",
	"rvList": "list-rv-list-7p4dJZtbmwr"
};
export default ___CSS_LOADER_EXPORT___;
